<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="openModal"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('closeModal', val)"
      @hidden="resetForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2">
          <h5 class="mb-0">Açılış Kartı</h5>
          <div>
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" class="p-2" autocomplete="off">
            <b-row>
              <b-col cols="12">
                <b-form-group label="Fiş Tarihi" label-for="fis_tarihi">
                  <flat-pickr v-model="form.tarih" class="form-control rounded-0" :config="config" :events="config.events" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="d-flex justify-content-start align-items-center py-1">
                  <b-form-checkbox
                    id="ac_statu"
                    :checked="form.gc_kodu == 'G' ? true : false"
                    @change="handlerDekont($event)"
                    switch
                  />
                  <label for="ac_statu" style="padding-top: 4px">{{
                    form.gc_kodu == 'G' ? 'Giriş Dekontu' : 'Çıkış Dekontu'
                  }}</label>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <validation-provider name="Fiyat" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Fiyat" label-for="tutar">
                    <b-form-input
                      class="rounded-0"
                      id="tutar"
                      name="tutar"
                      ref="tutar"
                      v-model="form.secilen_kur_toplam"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider name="Kur" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Kur" label-for="tutar">
                    <v-select
                      v-model="form.secilen_kur"
                      :options="anlikKurlar"
                      :reduce="(kur) => kur.banka_kodu"
                      label="banka_kodu"
                      :clearable="false"
                      class="invoice-filter-select"
                      @input="handlerKurChange"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <validation-provider name="Açıklama" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Açıklama" label-for="aciklama">
                    <b-form-textarea
                      id="aciklama"
                      v-model="form.aciklama"
                      placeholder="Açıklama"
                      rows="2"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />

                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-button squared block type="submit" variant="primary">
                  <feather-icon icon="CheckSquareIcon" class="mr-50" />
                  <span class="align-middle">{{ form.id == null ? 'Ekle' : 'Güncelle' }}</span>
                </b-button>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-button squared block @click="hide">
                  <feather-icon icon="XSquareIcon" class="mr-50" />
                  <span class="align-middle">İptal</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

        <b-row>
          <b-col>
            <b-card-title class="px-2 p-0">Anlık Kurlar</b-card-title>
            <b-card-body>
              <div v-for="item in anlikKurlar" :key="item.kod" class="transaction-item d-flex justify-content-between py-1">
                <b-media no-body>
                  <b-media-aside>
                    <b-avatar rounded size="42" variant="primary">
                      {{ item.kod }}
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ item.banka_kodu }}
                    </h6>
                    <small>{{ item.birim }}</small>
                  </b-media-body>
                </b-media>
                <div class="font-weight-bolder text-danger">{{ $t('kurlar.alis') }} {{ item.alis | cur }} ₺</div>
                <div class="font-weight-bolder text-success">{{ $t('kurlar.satis') }} {{ item.satis | cur }} ₺</div>
              </div>
            </b-card-body>
          </b-col>
        </b-row>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import flatPickr from 'vue-flatpickr-component';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import moment from 'moment';
export default {
  components: {
    vSelect,
    Cleave,
    flatPickr,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      config: {
        events: ['onChange', 'onClose', 'onDayCreate', 'onStartChange'],
        inline: false,
        mode: 'single',
        enableTime: true,
        dateFormat: 'd.m.Y H:i',
        locale: Turkish, // locale for this instance only
      },
      anlikKurlar: [],
      form: {
        _id: null,
        tarih: null,
        gc_kodu: 'G',
        aciklama: '',
        secilen_kur: 'TRY',
        secilen_kur_fiyat: 1,
        secilen_kur_toplam: 0,
      },
    };
  },
  async created() {
    await this.$store.dispatch('anlikKurListesi');
    this.anlikKurlar = this.$store.getters.getAnlikKurListesi;
    this.form.tarih = moment().format('DD.MM.YYYY HH:mm');
  },
  methods: {
    handlerDekont(event) {
      event == true ? (this.form.gc_kodu = 'G') : (this.form.gc_kodu = 'C');
    },
    // handlerOnChangeDate(event) {
    //   if (event) this.form.tarih = moment(String(event)).format('YYYY-MM-DD');
    // },

    handlerKurChange(event) {
      const kur = this.anlikKurlar.find((x) => x.banka_kodu == event);
      this.form.secilen_kur_fiyat = kur.satis;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.form = {
        _id: null,
        tarih: moment().format('YYYY-MM-DD'),
        gc_kodu: 'G',
        secilen_kur_fiyat: 1,
        secilen_kur_toplam: 0,
      };

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
      this.$refs.tutar.focus();
    },
    onSubmit() {
      if (this.form._id == null) {
        this.$store.dispatch('odemeEkle', this.form).then((res) => {
          if (res.data.success === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Ekleme`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: `Ekleme başarılı.`,
              },
            });
            this.resetForm();
          }
        });
      } else {
        this.$store.dispatch('odemeGuncelle', this.form).then((res) => {
          if (res.data.success === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Güncelleme`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: `Güncelleme başarılı.`,
              },
            });
            this.resetForm();
            this.$emit('closeModal', false);
          }
        });
      }
    },
  },
  watch: {
    updateData(val) {
      if (val != null) {
        this.form._id = val._id;
        this.form.k_no = val.k_no;
        this.form.tarih = val.tarih;
        this.form.gc_kodu = val.gc_kodu;
        this.form.secilen_kur = val.secilen_kur;
        this.form.secilen_kur_fiyat = val.secilen_kur_fiyat;
        this.form.secilen_kur_toplam = val.secilen_kur_toplam;
        this.form.aciklama = val.aciklama;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
