<template>
  <div>
    <b-card-actions
      ref="cardAction"
      title="Finans"
      icon="CreditCardIcon"
      :showLoading="show"
      :actionCollapse="true"
      :actionRefresh="true"
      :actionNewAdd="true"
      :collapsed="true"
      @refresh="refreshStop('cardAction')"
      @newAdd="handlerNewAdd($event)"
    >
      <template v-slot:filtrele> </template>
      <template v-slot:body>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: search,
          }"
          :pagination-options="{
            enabled: true,
            perPage: 100,
            mode: 'pages',
          }"
          styleClass="vgt-table striped bordered"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field === 'tarih'">
              <span>
                {{ props.row.tarih | momentFull }}
              </span>
            </div>
            <div v-else-if="props.column.field === 'rez_no'">
              <b-badge fill variant="secondary" class="rounded-0 mr-25">
                {{ props.row.rez_k_no }}
              </b-badge>
            </div>
            <div v-else-if="props.column.field === 'urun_no'">
              <b-badge fill variant="secondary" class="rounded-0 mr-25">
                {{ props.row.urun_k_no }}
              </b-badge>
            </div>
            <div v-else-if="props.column.field === 'uye_no'">
              <b-badge fill variant="secondary" class="rounded-0 mr-25">
                {{ props.row.user_k_no }}
              </b-badge>
            </div>

            <div v-else-if="props.column.field === 'tutar'">
              <b-badge
                fill
                :variant="props.row.gc_kodu == 'C' ? 'danger' : 'success'"
                v-b-tooltip.hover.top="'Seçilen Kur Fiyat Toplam'"
                class="d-block rounded-0 mr-25"
              >
                {{ props.row.secilen_kur_toplam | cur }}
                <small>{{ props.row.secilen_kur }}</small>
              </b-badge>
            </div>
            <div v-else-if="props.column.field === 'gc_kodu'">
              <b-badge
                class="rounded-0 mr-25"
                v-b-tooltip.hover.top="props.row.gc_kodu == 'C' ? 'Çıkış' : 'Giriş'"
                :variant="props.row.gc_kodu == 'C' ? 'light-danger' : 'light-success'"
              >
                {{ props.row.gc_kodu == 'C' ? 'C' : 'G' }}
              </b-badge>
            </div>
            <div v-else-if="props.column.field === 'odeme_yontemi'">
              <b-badge class="rounded-0" :style="`background-color:${odemeColor(props.row.odeme_yontemi)};`">
                {{ odemeText(props.row.odeme_yontemi) }}
              </b-badge>
            </div>
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                  </template>
                  <b-dropdown-item @click="handlerUpdate(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Düzenle</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="handlerRemove(props.row.k_no)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Sil</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <template slot="pagination-bottom">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <v-select
                  v-model="pageSize"
                  :options="perPageOptions"
                  :clearable="false"
                  class="invoice-filter-select"
                  @input="handlerPageSizeChange"
                />
                <span class="pl-2"> Toplam Kayıt : {{ count }}</span>
              </div>
              <div>
                <b-pagination
                  :total-rows="count"
                  :per-page="pageSize"
                  v-model="page"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handlerPageChange"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </template>
    </b-card-actions>

    <div class="d-flex justify-content-end">
      <b-card-normal title="Bakiye Durum" :showLoading="show">
        <template v-slot:body>
          <table class="table" style="min-width: 500px; border: none">
            <tbody>
              <tr v-for="bak in bakiye" :key="bak._id">
                <td :class="bakiyeHesapla(bak) >= 0 ? 'text-success' : 'text-danger'">{{ bak._id }} Toplam:</td>
                <td class="h5 text-right" :class="bakiyeHesapla(bak) >= 0 ? 'text-success' : 'text-danger'">
                  {{ bakiyeHesapla(bak) | cur }} {{ bak._id }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </b-card-normal>
    </div>
    <acilis-karti :openModal="openModal" :updateData="updateData" @closeModal="closeModal = $event" />
  </div>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import { VueGoodTable } from 'vue-good-table';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import AcilisKarti from './component/acilisKarti.vue';
export default {
  components: {
    BCardNormal,
    BCardActions,
    VueGoodTable,
    vSelect,
    AcilisKarti,
  },
  computed: {
    bakiyeHesapla() {
      return (bakiye) => {
        let giris = bakiye.giris;
        let cikis = bakiye.cikis;
        let toplam = giris - cikis;
        return toplam;
      };
    },
    odemeText() {
      const statusText = {
        1: 'Kapıda Ödeme',
        2: 'Havale EFT',
        3: 'Kredi Kartı',
      };

      return (status) => statusText[status];
    },
    odemeColor() {
      const statusColor = {
        1: '#1377d3',
        2: '#20c997',
        3: '#ff9f43',
      };

      return (status) => statusColor[status];
    },
  },
  data() {
    return {
      show: false,
      closeModal: false,
      updateData: {},
      openModal: false,

      count: 0,
      page: 1,
      pageSize: 25,
      perPageOptions: [5, 10, 25, 50, 100],
      search: '',
      columns: [
        {
          label: 'Tarih',
          field: 'tarih',
        },
        {
          label: 'Rez No',
          field: 'rez_no',
          width: '5%',
        },
        {
          label: 'Urun No',
          field: 'urun_no',
          width: '5%',
        },
        {
          label: 'Uye No',
          field: 'uye_no',
          width: '5%',
        },

        {
          label: 'Tutar',
          field: 'tutar',
        },
        {
          label: 'G/C',
          field: 'gc_kodu',
          width: '2%',
        },
        {
          label: 'T.Türü',
          field: 'odeme_yontemi',
          width: '2%',
        },
        {
          label: 'Açıklama',
          field: 'aciklama',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      bakiye: [],
    };
  },
  created() {
    this.handlerOdemeler();
  },
  methods: {
    async handlerOdemeler() {
      this.show = true;
      await this.$store.dispatch('odemeListele').then((res) => {
        this.count = res.data.count;
        this.rows = res.data.data;
        this.bakiye = res.data.bakiye;
      });
      this.show = false;
    },
    handlerFilters(event) {
      this.handlerOdemeler();
    },
    refreshStop(event) {
      this.handlerOdemeler();
    },
    // handlerFilterClear() {
    //   this.filter = {
    //     k_no: null,
    //     tarih_turu: 1,
    //     bas_tarih: null,
    //     bit_tarih: null,
    //     siralama: 1,
    //     statu: [],
    //     odeme: [],
    //   };
    //   this.handlerOdemeler();
    // },
    handlerNewAdd() {
      this.openModal = true;
    },
    handlerUpdate(task) {
      if (task) {
        this.updateData = task;
        this.openModal = true;
      }
    },
    handlerRemove(k_no) {
      this.$swal({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.$store.dispatch('odemeSil', k_no).then((res) => {
            if (res.data.success === true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: `Silme başarılı.`,
                },
              });
            }
          });
        }
      });
    },
    handlerPageChange(event) {
      this.page = event;
      this.handlerOdemeler();
    },
    handlerPageSizeChange(value) {
      this.pageSize = value;
      // this.page = 1;
      this.handlerOdemeler();
    },
  },
  watch: {
    closeModal(val) {
      if (val == false) {
        this.openModal = false;
        this.updateData = {};
        this.handlerOdemeler();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba($black, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
